.header {
  padding-left: 2.3rem;
  padding-right: 2.4rem;
  display: flex;
}

.header__logo {
  width: 9.5rem;
  height: 4.6rem;
  margin-top: 2.5rem;
}

.header__logoContainer {
  z-index: 2;
  justify-self: self-start;
}

@media (width >= 1024px) {
  .header {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .header ul {
    display: flex;
  }

  .header__logo {
    width: 20.8rem;
    height: 10.1rem;
    margin-top: 2.3rem;
  }
}
/*# sourceMappingURL=index.b76c1cb1.css.map */
