.header {
	display: flex;
	padding-left: 2.3rem;
	padding-right: 2.4rem;
}

.header__logo {
	margin-top: 2.5rem;
	width: 9.5rem;
	height: 4.6rem;
}

.header__logoContainer {
	z-index: 2;
	justify-self: self-start;
}

@media (min-width: 1024px) {
	.header {
		padding-left: 16rem;
		padding-right: 16rem;
	}

	.header ul {
		display: flex;
	}

	.header__logo {
		margin-top: 2.3rem;
		width: 20.8rem;
		height: 10.1rem;
	}
}
